// eslint-disable-next-line no-restricted-imports
import _ from 'underscore';
import Handlebars from 'legacy/handlebars';

const Routes = window.Routes || {};
Routes.Config = Routes.Config || {};
Routes.Person = Routes.Person || {};
Routes.Openings = Routes.Openings || {};
Routes.Approvals = Routes.Approvals || {};
Routes.Automation = Routes.Automation || { SourcingStrategies: {}, Stages: {} };

_.extend(Routes, {
  application_stage_tasks_path: Handlebars.compile(
    Routes.application_stage_tasks_path_template
  ),
  change_application_state_path: Handlebars.compile(
    Routes.change_application_state_path_template
  ),
  create_task_path: Handlebars.compile(Routes.create_task_path_template),
  destroy_task_path: Handlebars.compile(Routes.destroy_task_path_template),
  remove_department_modal_path: Handlebars.compile(
    Routes.remove_department_modal_path_template
  ),
  remove_office_modal_path: Handlebars.compile(
    Routes.remove_office_modal_path_template
  ),
  single_search_result_path: Handlebars.compile(
    Routes.single_search_result_path_template
  ),
  update_task_path: Handlebars.compile(Routes.update_task_path_template),
  update_task_status_path: Handlebars.compile(
    Routes.update_task_status_path_template
  ),
  hiring_plan_job_admins_path: Handlebars.compile(
    Routes.hiring_plan_job_admins_path_template
  ),
  update_hiring_plan_path: Handlebars.compile(
    Routes.update_hiring_plan_path_template
  ),
  people_gdpr_compliant_candidate_path: Handlebars.compile(
    Routes.people_gdpr_compliant_candidate_path
  ),
  hiring_plan_stages_json_path: Handlebars.compile(
    Routes.hiring_plan_stages_json_path_template
  ),
  office_hiring_plans_path: Handlebars.compile(
    Routes.office_hiring_plans_path_template
  ),
  job_status_report_path: Handlebars.compile(
    Routes.job_status_report_path_template
  ),
  job_approval_edit_path: Handlebars.compile(
    Routes.job_approval_edit_path_template
  ),
  credential_permissions_edit_path: Handlebars.compile(
    Routes.credential_edit_permissions_path_template
  ),
  categorized_api_endpoints_for_partner_path: Handlebars.compile(
    Routes.categorized_api_endpoints_for_partner_path_template
  ),
  credential_edit_path: Handlebars.compile(
    Routes.credential_edit_path_template
  ),
  credential_enable_path: Handlebars.compile(
    Routes.credential_enable_path_template
  ),
  credential_disable_path: Handlebars.compile(
    Routes.credential_disable_path_template
  ),
  credential_creation_path: Handlebars.compile(Routes.credential_creation_path),
  create_adobe_agreements_path: Handlebars.compile(
    Routes.create_adobe_agreements_path
  ),
  get_adobe_templates_path: Handlebars.compile(Routes.get_adobe_templates_path),
});

_.extend(Routes.Config, {
  custom_options_sources_configuration_path:
    Routes.Config.custom_options_sources_configuration_path,
});

_.extend(Routes.Person, {
  person_attachment_path: Handlebars.compile(
    Routes.Person.person_attachment_path_template
  ),
  attachment_preview_path: Handlebars.compile(
    Routes.Person.attachment_preview_path_template
  ),
});

_.extend(Routes.Openings, {
  openings_path: Handlebars.compile(Routes.Openings.openings_path_template),
  create_or_update_openings_path: Handlebars.compile(
    Routes.Openings.create_or_update_openings_path_template
  ),
  save_openings_details_path: Handlebars.compile(
    Routes.Openings.save_openings_details_path_template
  ),
  update_openings_path: Handlebars.compile(
    Routes.Openings.update_openings_path_template
  ),
  update_attributes_opening_path: Handlebars.compile(
    Routes.Openings.update_attributes_opening_path_template
  ),
});
_.extend(Routes.Approvals, {
  approvals_path: Handlebars.compile(Routes.Approvals.approvals_path_template),
  approvals_update_path: Handlebars.compile(
    Routes.Approvals.approvals_update_path_template
  ),
});
_.extend(Routes.CustomFields, {
  custom_field_path: Handlebars.compile(
    Routes.CustomFields.custom_field_path_template
  ),
  custom_field_options_path: Handlebars.compile(
    Routes.CustomFields.custom_field_options_path_template
  ),
  edit_custom_field_path: Handlebars.compile(
    Routes.CustomFields.edit_custom_field_path_template
  ),
  destroy_modal_path: Handlebars.compile(
    Routes.CustomFields.destroy_modal_path_template
  ),
});
_.extend(Routes.CustomFieldDependencies, {
  update_custom_field_dependency_path: Handlebars.compile(
    Routes.CustomFieldDependencies.update_dependency_path_template
  ),
  custom_field_dependency_parent_field_options_path: Handlebars.compile(
    Routes.CustomFieldDependencies
      .custom_field_dependency_parent_field_options_template
  ),
});

_.extend(Routes.Automation, {
  SourcingStrategies: {
    create_sourcing_strategy_rule_path:
      Routes.Automation.SourcingStrategies.create_sourcing_strategy_rule_path,
    update_sourcing_strategy_rule_path: Handlebars.compile(
      Routes.Automation.SourcingStrategies
        .update_sourcing_strategy_rule_path_template
    ),
    delete_sourcing_strategy_rule_path: Handlebars.compile(
      Routes.Automation.SourcingStrategies
        .destroy_sourcing_strategy_rule_path_template
    ),
  },
  Stages: {
    destroy_stage_rule_path: Handlebars.compile(
      Routes.Automation.Stages.destroy_stage_rule_path_template
    ),
    edit_stage_rule_path: Handlebars.compile(
      Routes.Automation.Stages.edit_stage_rule_path_template
    ),
  },
});

_.extend(Routes.Zendesk, {
  Sync: {
    user_path: Routes.Zendesk.Sync.user_path,
  },
});

export default Routes;
