import documentReady from 'document-ready';
import Ajax from 'legacy/ajax';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'legacy/routes'. 'app/webpack/javascripts/legacy/routes.js' implicitly has an 'any' type.
import Routes from 'legacy/routes';

// @ts-expect-error - TS2339 - Property 'ZendeskConfig' does not exist on type 'Window & typeof globalThis'.
const ZendeskConfig = window.ZendeskConfig || {};

documentReady(() => {
  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e: any) {
      return true;
    }
  }

  if (!inIframe()) {
    const tags: Array<any | string> = [];

    tags.push('Silo-id:' + ZendeskConfig.siloId);

    // @ts-expect-error - TS2339 - Property 'zESettings' does not exist on type 'Window & typeof globalThis'.
    window.zESettings = {
      webWidget: {
        helpCenter: {
          searchPlaceholder: {
            '*': 'Search for a solution',
          },
        },
        authenticate: {
          // @ts-expect-error - TS7006 - Parameter 'callback' implicitly has an 'any' type.
          jwtFn: function (callback) {
            callback(ZendeskConfig.widgetJwt);
          },
          chat: {
            // @ts-expect-error - TS7006 - Parameter 'callback' implicitly has an 'any' type.
            jwtFn: function (callback) {
              callback(ZendeskConfig.visitorAuthJwt);
            },
          },
        },
        chat: {
          departments: {
            enabled: [],
          },
          tags: tags,
        },
      },
    };

    // @ts-expect-error - TS2339 - Property 'zEmbed' does not exist on type 'Window & typeof globalThis'.
    window.zEmbed ||
      (function () {
        const queue: Array<any | any> = [];

        // @ts-expect-error - TS2339 - Property 'zEmbed' does not exist on type 'Window & typeof globalThis'.
        window.zEmbed = function () {
          queue.push(arguments);
        };

        // @ts-expect-error - TS2339 - Property 'zE' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'zE' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'zEmbed' does not exist on type 'Window & typeof globalThis'.
        window.zE = window.zE || window.zEmbed;
        // @ts-expect-error - TS2339 - Property 'zendeskHost' does not exist on type 'Document'.
        document.zendeskHost = ZendeskConfig.zendeskHost;
        // @ts-expect-error - TS2339 - Property 'zEQueue' does not exist on type 'Document'.
        document.zEQueue = queue;
      })();

    const syncUserOrganization = function () {
      Ajax.put({
        url: Routes.Zendesk.Sync.user_path,
        disableFlash: true,
        type: 'PUT',
      });
    };

    let hasIdentifiedUser = false;
    const identifyAndSyncZendeskUser = function () {
      if (hasIdentifiedUser) {
        return;
      }

      // @ts-ignore-error
      window.zE('webWidget', 'prefill', {
        name: {
          value: ZendeskConfig.userName,
          readOnly: true,
        },
        email: {
          value: ZendeskConfig.userEmail,
          readOnly: true,
        },
      });

      // @ts-ignore-error
      window.zE('webWidget', 'identify', {
        name: ZendeskConfig.userName,
        email: ZendeskConfig.userEmail,
        organization: ZendeskConfig.organizationName,
      });

      syncUserOrganization();

      hasIdentifiedUser = true;
    };

    document.addEventListener('zvaReady', function () {
      window.zoomCampaignSdk?.on('open', identifyAndSyncZendeskUser);
    });

    // @ts-ignore-error
    window.zE('webWidget:on', 'open', identifyAndSyncZendeskUser);
  }
});
